<template>
    <div>
        <app-collapse accordion>
            <media-form v-for="(media, mediaKey) in medias" :key="mediaKey" :media="media" :mediaKey="mediaKey" :dspOptions="dspOptions" @on-link-campaign="linkCampaign"> </media-form>
        </app-collapse>

        <br />
        <br />
        <h3>Lier toutes les campagnes <b-btn variant="info" @click="downloadTemplate">Télécharger le template</b-btn></h3>
        <br />
        <b-form-file type="file" v-model="globalLinkFile" @change="handleFileUpload($event)" accept=".csv"> </b-form-file>
        <b-btn variant="primary" class="mt-1" @click="globalLink">Lier les campagnes</b-btn>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
    import MediaForm from './media-form.vue';
    import FileSaver from 'file-saver';

    export default {
        components: {
            AppCollapse,
            MediaForm,
        },
        data() {
            return {
                medias: {},
                dspOptions: [],
                globalLinkFile: null,
            };
        },
        computed: {
            ...mapGetters({
                availableMediasCode: 'app/availableMediasCode',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                this.$root.toggleGlobalLoading(true);
                if (this.availableMediasCode.length === 0) {
                    await this.$store.dispatch('app/getAvailableMedias');
                }
                let promises = [];
                for (let media of this.availableMediasCode) {
                    promises.push(this.$store.dispatch('app/getCampaignsByMedia', media));
                }
                promises.push(this.$store.dispatch('app/getDspProviders'));
                Promise.all(promises)
                    .then((campaignsByMedia) => {
                        // ici on splice par ampaignsByMedia.length -2 pour ne pas prendre en compte les dsp qui ont été ajouté dans la liste de la promise
                        for (let camps of campaignsByMedia.splice(0, campaignsByMedia.length - 1)) {
                            this.defineCampaigns(camps);
                        }
                        let dspResponse = campaignsByMedia[campaignsByMedia.length - 1];
                        if (dspResponse.data) {
                            const dspAll = dspResponse.data.map((dsp) => dsp.name);
                            for (let dsp of dspAll) {
                                this.dspOptions.push({
                                    name: dsp,
                                    value: dsp,
                                });
                            }
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(errorToast, 'Erreur lors du fetch des campaignes par média');
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },

            defineCampaigns(campaigns) {
                if (campaigns.data.data.length === 0) return;
                let mediaCode = campaigns.data.data[0].media_support_code;
                this.$set(this.medias, mediaCode, {
                    campaignOptions: [],
                    id: '',
                });

                for (let camp of campaigns.data.data) {
                    let labelQuotation = '';
                    if (camp.details) labelQuotation = camp.details.point_code + ' - ';
                    if (camp.start_date && camp.end_date) {
                        labelQuotation += 'Du ' + camp.start_date + ' au ' + camp.end_date;
                    }
                    this.medias[mediaCode].campaignOptions.push({
                        id: camp.campaign_code,
                        label: labelQuotation + ' - ' + camp.campaign_code,
                    });
                }
                // if(mediaCode === 'FBK') {
                //     this.fbkSpecialCase(campaigns);
                // }
            },
            handleFileUpload(event) {
                this.globalLinkFile = event.target.files[0];
            },
            downloadTemplate() {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/getCampaignTemplate')
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                        const blob = new Blob([res.data], {
                            type: res.headers['content-type'],
                        });

                        FileSaver.saveAs(blob, 'campaigns_template.csv');
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Le template a été téléchargé',
                                icon: 'smile',
                                variant: 'success',
                            },
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Erreur lors du téléchargement du template',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    });
            },
            globalLink() {
                if (this.globalLinkFile !== null) {
                    let data = new FormData();

                    data.append('file', this.globalLinkFile);

                    this.$root.toggleGlobalLoading(true);
                    this.$store
                        .dispatch('app/linkCampaigns', {
                            data,
                        })
                        .then((res) => {
                            this.$toast(this.successToast('Les campagnes ont été liées avec succès'));
                        })
                        .catch((e) => {
                            this.globalLinkFile = null;
                            this.$toast(this.errorToast("Les campagnes n'ont pas pu être liées"));
                        })
                        .finally(() => {
                            this.globalLinkFile = null;
                            this.$root.toggleGlobalLoading(false);
                        });
                } else {
                    this.$toast(this.errorToast("Vous n'avez pas uploadé de fichier CSV"));
                }
            },

            fbkSpecialCase(campaigns) {},
            linkCampaign(campaign, media, dsp, id) {
                this.$root.toggleGlobalLoading(true);
                let data = {
                    provider: dsp,
                    media,
                    id,
                };
                this.$store
                    .dispatch('app/linkCampaign', {
                        code: campaign,
                        data,
                    })
                    .then((res) => {
                        this.$toast(this.successToast('Les campagnes ont été liées avec succès'));
                    })
                    .catch((e) => {
                        this.$toast(this.errorToast("Les campagnes n'ont pas pu être liées"));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
        },
    };
</script>

<style></style>
