<template>
    <app-collapse-item :title="`Lier une campagne ${mediaKey}`">
        <b-form @submit.stop.prevent="onSubmit">
            <b-form-group :label="`Campagnes ${mediaKey} LMP`" label-for="select-campaign">
                <b-form-select id="select-campaign" v-model="selectedCampaign" :options="media.campaignOptions" text-field="label" value-field="id" :state="validateSelectedCampaign"></b-form-select>
                <b-form-invalid-feedback :state="validateSelectedCampaign"> Une campagne doit être sélectionnée </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="DSP" label-for="select-dsp">
                <b-form-select id="select-dsp" v-model="selectedDsp" :options="dspOptions" text-field="name" value-field="value" :state="validateSelectedDsp"></b-form-select>
                <b-form-invalid-feedback :state="validateSelectedDsp"> Un DSP doit être sélectionné </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="ID" label-for="select-id">
                <b-input id="selecte-id" v-model="id" :state="validateId" />
                <b-form-invalid-feedback :state="validateId"> Une ID doit être saisie </b-form-invalid-feedback>
            </b-form-group>
            <b-btn variant="primary" class="mt-1" type="submit"> Lier</b-btn>
        </b-form>
    </app-collapse-item>
</template>

<script>
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
    import { mapGetters } from 'vuex';
    export default {
        components: {
            AppCollapseItem,
        },
        props: {
            media: {
                type: Object,
                required: true,
            },
            mediaKey: {
                type: String,
                required: true,
            },
            dspOptions: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                selectedCampaign: null,
                selectedDsp: null,
                id: '',
                renderErrors: false,
            };
        },
        computed: {
            ...mapGetters({
                errorToast: 'app/errorToast',
            }),
            validateSelectedCampaign() {
                return this.selectedCampaign !== null;
            },
            validateSelectedDsp() {
                return this.selectedDsp !== null;
            },
            validateId() {
                return this.id.length > 0;
            },
        },
        methods: {
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            onSubmit() {
                if (
                    [this.validateSelectedCampaign, this.validateSelectedDsp, this.validateId].some((val) => {
                        return val === false;
                    })
                ) {
                    this.renderErrors = true;
                    return this.$toast(this.errorToast('Corrigez les erreurs du formulaires avant de soumettre'));
                }
                this.renderErrors = false;
                this.openAlert();
            },
            openAlert() {
                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir lier les campagnes ?', {
                        title: 'Please Confirm',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'primary',
                        okTitle: 'YES',
                        cancelTitle: 'NO',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((confirmed) => {
                        if (confirmed) {
                            this.$emit('on-link-campaign', this.selectedCampaign, this.mediaKey, this.selectedDsp, this.id);

                            for (let i = 0; i < this.media.campaignOptions.length; i++) {
                                if (this.media.campaignOptions[i].id === this.selectedCampaign) {
                                    this.$delete(this.media.campaignOptions, i);
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        },
    };
</script>

<style></style>
